@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  position: relative;

  &_active {
    max-height: 100vh;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
  }
}

.modal {
  width: 100%;
  height: 100vh;
  justify-content: center;
  flex-grow: 1;
  position: absolute;
  top: 0;
  z-index: 1000;
  padding: 40px;
  box-sizing: border-box;
  overflow: hidden;
  pointer-events: none;
  backdrop-filter: none;
  background-color: transparent;
  @include transition(background-color, backdrop-filter);

  &_active {
    background: rgba(0, 0, 0, 0.8);
    overflow: auto;
    pointer-events: all;
    backdrop-filter: blur(4px);
  }
}

.content {
  width: 100%;
  height: max-content;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  background: $background-color;
  opacity: 0;
  padding: 20px;
  box-sizing: border-box;
  transform: translateY(10px) scale(0.96);
  @include transition(opacity, transform);

  &_active {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerTitle {
  font-weight: 300;
  color: $text-primary-color;
  @include transition(font-size);
  @include font-xl;
}

.closeIcon {
  transform: rotate(-45deg);
  cursor: pointer;
  @include transition(transform);

  &:hover {
    transform: scale(1.1) rotate(-135deg);
  }
}

@include mobile-lg {
  .modal {
    padding: 100px;
  }

  .content {
    padding: 40px;
  }

  .headerTitle {
    @include font-2xl;
  }
}

@include laptop {
  .modal {
    padding: 200px;
  }
}
