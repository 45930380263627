@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.content {
  width: 100%;
  max-width: $laptop-width;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 40px;
  box-sizing: border-box;
}

.hero {
  width: 100%;
  height: 250px;
  background: url(../../../assets/images/main-layout-hero.jpg) bottom/cover;
}
