@import "../../../styles/variables.scss";

.icon {
  transition: all 0.3s ease-in-out;

  path,
  rect {
    transition: all 0.3s ease-in-out;
    fill: $text-primary-color;
  }
}
