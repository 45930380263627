@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.pickerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.label {
  color: $text-primary-color;
  text-transform: uppercase;
  @include font-lg;
}

.picker {
  display: flex;
  gap: 20px;
  flex: 1;
  padding: 20px;
  border: 1px dashed $primary-color;
  box-sizing: border-box;
  @include transition(background-color);

  &_dragOver {
    background-color: rgba($color: $primary-color, $alpha: 0.1);
  }
}

.uploadIcon {
  path {
    fill: $primary-color;
  }
}

.text {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.uploadButton {
  color: $input-placeholder-color;
  font-weight: 400;
  @include font-lg;

  span {
    color: $primary-color;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
  }
}

.size {
  color: $text-primary-color;
  @include font-base;
}

.fileContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.file {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  background: $primary-color;
  color: $text-secondary-color;
}

.removeButtonContainer {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  span {
    color: $primary-color;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
  }
}

.error {
  min-height: 24px;
  display: inline-block;
  color: $error-color;
}

@include tablet {
  .pickerContainer {
    display: grid;
    grid-template-areas: "picker picker picker file file";
  }

  .picker {
    grid-area: picker;
  }

  .fileContainer {
    grid-area: file;
  }
}
