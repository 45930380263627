@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
  width: 100%;
  height: 100%;
  grid-area: 1 / 1 / 2 / 2;
  background: url(../../../assets/images/search-bg.jpg) center/cover;
  display: flex;
  justify-content: center;
}

.content {
  max-width: $laptop-width;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 40px;
  box-sizing: border-box;
}

.title {
  width: 100%;
  text-transform: uppercase;
  color: $text-secondary-color;
  font-weight: 300;
  @include font-3xl;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  div[class^="CustomSelect_field"] {
    color: $text-primary-color;
  }
}

.button {
  width: 100%;
  background-color: $search-button-background;
}

@include mobile-lg {
  .title,
  .form {
    width: $mobile-md-width;
  }
}

@include tablet {
  .title {
    width: 100%;
  }

  .form {
    width: 100%;
    flex-direction: row;
  }
  .searchInputContainer {
    width: 43%;
  }

  .selectDateContainer {
    width: 18%;
  }

  .durationContainer {
    width: 18%;
  }

  .searchButtonContainer {
    width: 20%;
  }
}
