@import "../../../../../styles/mixins.scss";
@import "../../../../../styles/variables.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.title {
  text-transform: uppercase;
  @include font-2xl;
}

.selectContainer {
  display: flex;
  flex-direction: column;
}

.select {
  border: 1px solid $element-background-secondary !important;
  color: $input-placeholder-color !important;
}
