@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo {
  width: 50px;
  height: 50px;
}

.text {
  color: $text-secondary-color;
  text-transform: uppercase;
}

.title {
  @include font-2xl;
  font-weight: 400;
  letter-spacing: 5px;
  margin-bottom: 0;
}

.slogan {
  @include font-sm;
  font-weight: 200;
  letter-spacing: 2px;
  margin-bottom: 0;
}
