@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.label {
  color: $text-primary-color;
  text-transform: uppercase;
  @include font-lg;
}

.fieldContainer {
  width: 100%;
  position: relative;
}

.field {
  width: 100%;
  height: 66px;
  box-sizing: border-box;
  color: $input_text_color;
  background: $text-secondary-color;
  border: 1px solid $element-background-secondary;
  outline: 0;
  padding: 0 10px;
  transition: all 0.3s ease-in-out;
  @include font-lg;

  &_error {
    border: 1px solid $error-color !important;
  }

  &_with_icon {
    padding-right: 48px;
  }

  &::placeholder {
    color: $input-placeholder-color;
    transition: all 0.3s ease-in-out;
  }

  &:disabled {
    background: $element-background-secondary;
    cursor: not-allowed;
  }
}

.icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.error {
  min-height: 24px;
  display: inline-block;
  color: $error-color;
}
