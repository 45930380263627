@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title {
  text-transform: uppercase;
  font-weight: 300;
  @include font-2xl;
}

.items {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  transition: all 0.3s ease-in-out;
}

.item {
  width: 100%;
  border: 2px solid $primary-color;
}

.itemHeader {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  border-bottom: 2px solid $primary-color;
  color: $primary-color;
  text-transform: uppercase;
  font-weight: 600;
  @include font-lg;
  transition: all 0.3s ease-in-out;

  &_active {
    background-color: $primary-color;
    color: $text-secondary-color;
  }
}

.itemBody {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  color: $text-primary-color;
  text-transform: uppercase;
  font-weight: 600;
  @include font-lg;
}

.itemFooter {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &_unchosen {
    p {
      cursor: pointer;
      text-decoration: underline;

      &:hover {
        color: $primary-color;
      }
    }
  }

  p {
    color: $secondary-color;
    text-transform: uppercase;
    font-weight: 600;
    @include font-base;
    transition: all 0.3s ease-in-out;
  }
}

@include tablet {
  .items {
    grid-template-columns: repeat(2, 1fr);
  }
}
