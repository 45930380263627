@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  min-width: 100%;
  max-width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.filtersHeader {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  width: 100%;

  h1 {
    font-weight: 300;
    font-size: 40px;
  }

  .startBooking {
    height: 67px;
    width: 300px;
    background-color: $primary-color;
    border: 0;
    color: white;
    font-size: 18px;
  }

  .resetFilters {
    border: 0;
    text-decoration: underline;
    background-color: inherit;
  }
}

.form {
  min-width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.button {
  width: 100%;
}

.input {
  label,
  input {
    color: $text-primary-color !important;
    background: $element-background-secondary;

    &::placeholder {
      color: rgba($text-primary-color, $alpha: 0.5) !important;
      text-transform: uppercase;
    }

    &:focus {
      background: $delimitter-line-color !important;
    }
  }
}

.inputDate {
  label,
  input {
    color: $text-primary-color !important;
    background: $element-background-secondary !important;

    &::placeholder {
      color: rgba($text-primary-color, $alpha: 0.5) !important;
      text-transform: uppercase;
    }

    &:focus {
      background: $delimitter-line-color !important;
    }
  }
}

.title {
  width: 100%;
  text-transform: uppercase;
  color: $text-secondary-color;
  font-weight: 300;
  @include font-3xl;
}

.badgeContainer {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin-top: -40px;

  .badge {
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
    padding: 12px;
    background-color: rgba($primary-color, 0.3);
    border: rgba($text-primary-color, 0.17) 1px solid;
    border-radius: 12px;

    span {
      color: $primary-color;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      margin-right: 15px;
    }

    .closeIcon {
      font-weight: 600;
      width: 20px;
      height: 20px;
      transform: rotate(-45deg);
      cursor: pointer;
      margin-top: 2px;

      path {
        fill: $primary-color;
      }
    }
  }
}

.fieldsContainer {
  min-width: 100%;
  max-width: 100%;
  overflow-x: auto;

  .headerRow {
    display: flex;
    align-items: center;
    background-color: rgba($delimitter-line-color, 0.3);
    width: 100%;
    min-width: 1190px;
    height: 78px;
    border: rgba($text-primary-color, 0.14) 1px solid;
    padding: 0 20px;
  }

  .contentRow {
    display: flex;
    align-items: center;
    background-color: $text-secondary-color;
    width: 100%;
    min-width: 1190px;
    height: 102px;
    border: $primary-color 2px solid;
    padding: 0 20px;
    margin-top: 20px;
  }

  .column {
    display: flex;
    flex-direction: row;

    &_sm {
      width: 150px;
    }

    &_md {
      width: 200px;
    }

    &_lg {
      width: 250px;
    }

    .svgBlock {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      height: 100%;
      padding-top: 4px;

      .arrowUp,
      .arrowDown {
        width: 7px;
        height: 7px;
        border-radius: 2px;
      }

      .arrowUp {
        margin-bottom: 3px;
      }

      .arrowDown {
        transform: rotate(180deg);
      }
    }

    span {
      color: $text-primary-color;
      font-weight: 600;
      font-size: 16px;
    }

    .uppercase {
      text-transform: uppercase;
    }
  }

  .actionColumn {
    display: flex;
    justify-content: flex-end;
    width: 100px;

    .svgAction {
      width: 24px;
      height: 24px;
      cursor: pointer;

      path {
        fill: $primary-color;
      }
    }
  }
}

@include mobile-sm {
  .fieldsContainer {
    max-width: $mobile-sm-width;
  }
}

@include mobile-lg {
  .form {
    width: $mobile-md-width;
    flex-direction: column;
  }

  .filtersHeader {
    flex-direction: column;
  }

  .fieldsContainer {
    max-width: $mobile-lg-width;
  }
}

@include tablet {
  .title {
    width: 100%;
  }

  .form {
    width: 100%;
    flex-direction: row;
  }

  .filtersHeader {
    flex-direction: row;
  }

  .fieldsContainer {
    max-width: 100%;
  }
}

@include laptop {
  .fieldsContainer {
    .headerRow,
    .contentRow {
      min-width: 100%;
      max-width: 100%;
    }
  }
}
