@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container,
.content,
.topSection {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.content {
  gap: 40px;
}

.crumbsAndButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: $mobile-md-width) {
    flex-direction: column;
  }

  .backButton {
    height: 67px;
    width: 334px;
    background-color: $primary-color;
    border: 0;
    color: white;
    font-size: 18px;
    text-transform: uppercase;
    margin-top: 20px;

    @media screen and (max-width: $mobile-md-width) {
      margin-top: 0;
    }
    @media screen and (max-width: $mobile-base-width) {
      width: 100%;
    }
  }
}

.mainImage {
  width: 100%;
}

.reservationInfo {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.reservationItem {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding: 20px;
  box-sizing: border-box;
  background: $element-background-primary;
}

.pnr {
  font-weight: 600;
  color: $primary-color;
  text-transform: uppercase;
  @include font-4xl;
}

.cruiseName {
  color: $primary-color;
  @include font-xl;
}

.ship {
  font-weight: 400;
  color: $subtext-color;
  @include font-lg;
}

.embarking {
  font-weight: 600;
  color: $text-primary-color;
  margin-top: 10px;
  @include font-lg;
}

.guestInfo {
  &_primary {
    font-weight: 500;
    color: $text-primary-color;
    @include font-xl;
  }

  &_secondary {
    font-weight: 400;
    color: $subtext-color;
    @include font-lg;
  }
}

.priceInfo {
  &_price {
    font-weight: 600;
    color: $text-primary-color;
    @include font-3xl;
  }

  &_description {
    font-weight: 400;
    color: $subtext-color;
    @include font-lg;
  }
}

.tableContainer {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  box-sizing: border-box;
  background: $element-background-primary;

  &_title {
    font-weight: 600;
    text-transform: uppercase;
    color: $text-primary-color;
    @include font-2xl;
  }

  &_subtitle {
    margin-top: 20px !important;
    color: $primary-color;
    font-weight: 500;
    @include font-xl;
  }
}

.tableWrapper {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;

  table {
    min-width: 1000px;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 20px;
  }

  thead {
    height: 46px;
    background: $element-background-secondary;

    th {
      padding: 0 20px;
      border: 1px solid $delimitter-line-color;
      border-left-width: 0;
      border-right-width: 0;
      box-sizing: border-box;
      color: $text-primary-color;
      text-transform: uppercase;
      font-weight: 600;
      @include font-lg;

      &:first-of-type {
        border-left-width: 1px;
      }

      &:last-of-type {
        border-right-width: 1px;
      }
    }
  }

  tbody {
    tr {
      height: 130px;
      padding: 20px;
      background: $background-color;
    }

    td {
      height: 130px;
      padding: 0 20px;
      border: 1px solid $delimitter-line-color;
      border-left-width: 0;
      border-right-width: 0;
      box-sizing: border-box;
      color: $subtext-color;
      @include font-lg;

      p span {
        font-weight: 600;
      }

      &:first-of-type {
        border-left-width: 1px;
      }

      &:last-of-type {
        border-right-width: 1px;
      }
    }
  }
}

.info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cabin {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: $background-color;
  padding: 20px;
  border: 1px solid $delimitter-line-color;
  box-sizing: border-box;
}

.guests {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.guest {
  padding: 8px 16px;
  border: 1px solid $delimitter-line-color;
  box-sizing: border-box;
  background: $element-background-secondary;
  border-radius: 10px;

  p {
    color: $subtext-color;
    text-transform: uppercase;
    @include font-base;
  }
}

.deckInfo {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  p {
    @include font-lg;

    span {
      font-weight: 500;
    }

    &:first-of-type {
      color: $primary-color;
    }

    &:last-of-type {
      color: $subtext-color;
    }
  }
}

.gradeInfo {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.gradeName {
  color: $text-primary-color;
  font-weight: 600;
  @include font-2xl;
}

.gradeDescription {
  color: $subtext-color;
  @include font-lg;
}

.paymentContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@include tablet {
  .topSection {
    display: grid;
    grid-template-columns: 1.2fr 0.8fr;
    gap: 20px;
  }

  .mainImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .reservationInfo {
    display: grid;
    grid-template-rows: 1.4fr 1fr 1fr;
  }

  .reservationItem {
    gap: 14px;
  }

  .tableContainer,
  .cabin {
    padding: 40px;
    gap: 40px;
  }

  .paymentContainer {
    display: grid;
    grid-template-columns: 1.1fr 0.9fr;
    gap: 20px;

    table {
      min-width: unset;
    }
  }
}
